import {mapGetters, mapActions} from "vuex";

import VueAdsPagination, {VueAdsPageButton} from 'vue-ads-pagination';
import cardMedia from '@/components/cards/card-media/index.vue'
import emptyData from '@/components/empty-data/index.vue'

export default {
    name: 'current-article',
    components: {
        VueAdsPagination,
        VueAdsPageButton,
        cardMedia,
        emptyData
    },
    data() {
        return {
            article: {
                // title: 'test'
            },

        }

    },
    beforeUpdate() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },
    computed: {
        ...mapGetters({
            blogList: `blog/blogList`,
            isLoadingBlog: `blog/isLoadingBlog`,
            blogMeta: `blog/blogMeta`,
            blogLimit: `blog/blogLimit`,
            currentTitle: `test`

        }),

    },
    methods: {
        ...mapActions({
            fetchBlogList: `blog/BLOG_DATA_GET`,
        }),

    },
    created() {

        let routeId = parseInt(this.$route.params.id)

        this.fetchBlogList({limit: 100}).then(() => {

            this.blogList.forEach(el => {
                console.log(typeof(el.id));
                if (el.id === routeId) {
                    this.article = el
                }
            })
        })

        // return this.route.meta.bcLabel = 'fdf'

        // if (this.route.meta.bcGetter) {
        //     // return this.route.meta.bcGetter = 'fdfdf'
        // }
    }
};
